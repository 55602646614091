import * as Phaser from 'phaser';
import { BAL, CONFIG, FOOD_YUM_STOPS } from './consts';
import type { FOOD_YUM_STOP } from './consts';
import { HealthBar } from './UI';

// ─── Consts ──────────────────────────────────────────────────────────────────

const sizeX = CONFIG.tileSize
const sizeY = CONFIG.tileSize


export class Food extends Phaser.Physics.Arcade.Sprite {

  // ─── Properties ──────────────────────────────────────────────────────

  yum: number;
  maxYum: number;
  healthBar: HealthBar;
  isGross: boolean;
  isAnimPlaying: boolean;
  onBecomeGross: () => void;

  // ─── Constructor ─────────────────────────────────────────────────────

  constructor(scene: Phaser.Scene, x: number, y: number, startYum: number, onBecomeGross?: () => void) {

    // ─── Core Init ───────────────────────────────────────────────

    super(scene, x, y, 'food');
    scene.physics.world.enable(this);
    scene.add.existing(this);

    // ─── Properties ─────────────────────────────────────────

    this.yum = startYum;
    this.isGross = false;
    this.maxYum = BAL.foodYumRange[1]
    this.onBecomeGross = onBecomeGross;
    this.isAnimPlaying = false;

    // ─── Sprite Init ─────────────────────────────────────────────

    this.setSize(sizeX / 2, sizeY / 2); // set size of player
    this.setDisplaySize(sizeX / 2, sizeY / 2); // set size of player
    this.updateAppearance();

    // ─── Addons ──────────────────────────────────────────────────
    this.healthBar = new HealthBar(scene, this);
    this.healthBar.update(this.yum);

    // ——— Configure Animation ————————————————————————————————————————

    this.startPulseAnim()
  }

  /**
   * Decrease yum value of food.
   * @param amount Amount to decrease yum by.
   */
  decreaseYum(amount: number) {
    this.yum = Phaser.Math.Clamp(this.yum - amount, ...BAL.foodYumRange);

    if ((this.yum <= BAL.foodYumThresholds.bad) && !this.isGross) {
      this.isGross = true;
      if (this.onBecomeGross) {
        this.onBecomeGross()
      };
    }
  }

  update() {
    this.healthBar.update(this.yum);
    this.updateAppearance();
  }

  private startPulseAnim() {
    if (!this.isAnimPlaying) {
      this.isAnimPlaying = true;
      const tweenCfg: Phaser.Types.Tweens.TweenBuilderConfig = {
        targets: this,
        duration: Phaser.Math.Between(140, 160),
        scaleX: 1 + Math.random() * 0.1,
        scaleY: 1 + Math.random() * 0.1,
        alpha: 0.8,
        yoyo: true,
        loop: 0,
        ease: 'Back.easeInOut',
        onComplete: () => {
          this.isAnimPlaying = false;
          if (this.scene) {
            this?.scene?.time?.delayedCall(
              Phaser.Math.Between(500, 1000),
              () => this.startPulseAnim()
            );
          }
        }
      };
      this?.scene?.tweens?.add(tweenCfg);
    }
  }


  static interpretYum(yum: number): FOOD_YUM_STOP {
    if (yum <= BAL.foodYumThresholds.trash) {
      return FOOD_YUM_STOPS.trash;
    } else if (yum <= BAL.foodYumThresholds.bad) {
      return FOOD_YUM_STOPS.bad;
    } else if (yum <= BAL.foodYumThresholds.good) {
      return FOOD_YUM_STOPS.med;
    } else {
      return FOOD_YUM_STOPS.good;
    }
  }

  private updateAppearance() {
    const yumStop = Food.interpretYum(this.yum);

    switch (yumStop) {
      case FOOD_YUM_STOPS.trash:
        this.setTexture('food-trash');
        break;
      case FOOD_YUM_STOPS.bad:
        this.setTexture('food-bad');
        break;
      case FOOD_YUM_STOPS.med:
        this.setTexture('food-med');
        break;
      case FOOD_YUM_STOPS.good:
        this.setTexture('food-good');
        break;
    }
  }

  destroy(fromScene?: boolean) {
    this.healthBar.destroy();
    super.destroy(fromScene);
  }
}
