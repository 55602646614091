export class InputState {
  isInputEnabled: boolean;

  constructor() {
    this.isInputEnabled = true;
  }

  disableInput() {
    this.isInputEnabled = false;
  }

  enableInput() {
    this.isInputEnabled = true;
  }
}
