import * as Phaser from 'phaser';
import { CONFIG, GAME_EVENTS } from './consts';
import { IntroScene } from './IntroScene';
import { MainScene } from './MainScene';
import { OutroScene } from './OutroScene';
import { BootScene } from './BootScene';

const config = {
    type: Phaser.AUTO,
    backgroundColor: '#ff771a',
    width: CONFIG.width,
    height: CONFIG.height,
    physics: {
        default: "arcade",
        arcade: {
            gravity: { y: 0 },
        },
    },
    resolution: window.devicePixelRatio,
    scene: [BootScene, IntroScene, MainScene, OutroScene],
};

const game = new Phaser.Game(config);

// GAME EVENTS

game.events.on(GAME_EVENTS.dimScreen, function () {
    const html = document.querySelector('html');
    html.classList.add('dim');
});

game.events.on(GAME_EVENTS.undimScreen, function () {
    const html = document.querySelector('html');
    html.classList.remove('dim');
});

window.addEventListener('focus', () => {
    game.canvas.focus();
});
