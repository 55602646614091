import * as Phaser from 'phaser';
import { CONFIG } from './consts';

// ─── Consts ──────────────────────────────────────────────────────────────────

const sizeX = CONFIG.tileSize
const sizeY = CONFIG.tileSize

export class Obstacle extends Phaser.Physics.Arcade.Sprite {

  // ─── Constructor ─────────────────────────────────────────────────────

  constructor(scene: Phaser.Scene, x: number, y: number) {

    // ─── Core Init ───────────────────────────────────────────────

    super(scene, x, y, 'table-1');
    scene.physics.world.enable(this);
    scene.add.existing(this);

    // ─── Properties ─────────────────────────────────────────

    this.setSize(sizeX / 2, sizeY / 2);
    this.setDisplaySize(sizeX, sizeY);
  }
}
