import * as Phaser from 'phaser';
import { SCENES, TEST_STAT } from './consts';

declare var WebFont: any;

export class BootScene extends Phaser.Scene {
  constructor() {
    super({ key: SCENES.boot });
  }

  preload() {
    const webFontFileURL = "https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js";

    // Load WebFont Library
    this.load.script('webfont-loader', webFontFileURL);

    // WebFont Config
    let WebFontConfig = {
      custom: {
        families: ['Nunito Sans Extra', 'Nunito Sans Semi'],
      },
      active: () => {
        // Start the next scene only after font is loaded
        this.scene.start(SCENES.intro);
        // this.scene.start(SCENES.outro, TEST_STAT);
      }
    };

    // Use WebFont script once it's loaded
    this.load.once('complete', function () {
      WebFont.load(WebFontConfig);
    }, this);
  }
}
