import * as Phaser from 'phaser';
import { SCENES, CONFIG, GAME_NAME, PLAYER_SPRITES } from './consts';
import { Label } from './UI';

const screenCenterX = CONFIG.width / 2;

const titleY = CONFIG.height * 0.2;
const spritesY = CONFIG.height * 0.38;
const helpY = CONFIG.height * 0.53;
const keysY = CONFIG.height * 0.825;
const captionY = CONFIG.height * 0.925;

export class IntroScene extends Phaser.Scene {
  textContentIndex: number
  cursors: Phaser.Types.Input.Keyboard.CursorKeys;
  container: Phaser.GameObjects.Container;

  constructor() {
    super({ key: SCENES.intro });
  }

  preload() {
    Object.values(PLAYER_SPRITES).forEach((sprite) => {
      this.load.image(sprite, `assets/${sprite}.png`);
    });
    this.load.image('keys', 'assets/keys.png');
    this.load.image('food-good', 'assets/food-good.png');
    this.load.image('food-trash', 'assets/food-trash.png');

  }

  create() {

    // ─── Text ────────────────────────────────────────────────────

    const title = new Label(this, screenCenterX, titleY, GAME_NAME, 'xl');
    title.setWeight('bold');

    const caption = new Label(this, screenCenterX, captionY, "B E G I N", 'sm');
    caption.setWeight('bold');

    const arrows = this.add.image(screenCenterX, keysY, 'keys')
      .setOrigin(0.5, 0.5)
      .setDepth(1);

    this.tweens.add({
      targets: [caption.getTextObject(), arrows],
      alpha: 0.4,
      yoyo: true,
      duration: 400,
      ease: 'Sine.easeInOut',
      loop: -1
    });

    // ─── Container ───────────────────────────────────────────────

    this.container = this.add.container(0, 0, [arrows, title.getTextObject(),
      caption.getTextObject()]);
    this.container.setAlpha(0);

    this.tweens.add({
      targets: [this.container],
      alpha: 1,
      duration: 800,
      ease: 'Sine.easeInOut',
    });

    const startY = helpY;

    [
      `Get food before it goes bad.`,
      `End the week with no leftovers to win.`,
      `Go faster for bragging rights.`
    ].forEach((helpText, index) => {
      const label = new Label(this, screenCenterX, startY + (0.5 * CONFIG.tileSize * index), helpText, 'sm')
      label.getTextObject().setDepth(4);
    })

    // ─── Background ──────────────────────────────────────────────

    // Shuffle the sprite keys
    const spriteKeys = [...Object.values(PLAYER_SPRITES), "food-good", "food-good", "food-good", "food-trash", "food-good", "food-trash"];

    for (let i = spriteKeys.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [spriteKeys[i], spriteKeys[j]] = [spriteKeys[j], spriteKeys[i]];
    }

    const circleRadius = 7000; // The radius of the circle dance pattern
    const numberOfSprites = 200; // Number of sprites in the dance
    const spritesContainer = this.add.container(CONFIG.width / 2, CONFIG.height / 2).setPosition(CONFIG.width / 2, spritesY + circleRadius)
    const rotationDuration = 700000;
    const rotationEase = 'Linear';
    const spritesArray: Phaser.GameObjects.Sprite[] = [];

    for (let i = 0; i < numberOfSprites; i++) {
      const spriteKey = spriteKeys[i % spriteKeys.length];

      const angle = (i / numberOfSprites) * Math.PI * 2; // Calculate angle for each sprite
      const x = circleRadius * Math.cos(angle);
      const y = circleRadius * Math.sin(angle);
      const sprite = this.add.sprite(x, y, spriteKey); // Sprite is positioned relative to center
      sprite.setScale(2);
      sprite.setDepth(0); // Behind the text
      spritesArray.push(sprite);
      spritesContainer.add(sprite);

      this.tweens.add({
        targets: sprite,
        angle: 360,
        duration: rotationDuration,
        ease: rotationEase,
        loop: -1,
      });

    }

    this.tweens.add({
      targets: spritesContainer,
      angle: -360,
      duration: rotationDuration,
      ease: rotationEase,
      loop: -1
    });

    // ─── Input ────────────────────────────────────────────────────

    this.cursors = this.input.keyboard.createCursorKeys();
  }

  // ─── Handle Updates ──────────────────────────────────────────────────

  update() {
    if (Phaser.Input.Keyboard.JustDown(this.cursors.up) || Phaser.Input.Keyboard.JustUp(this.cursors.down) ||
      Phaser.Input.Keyboard.JustDown(this.cursors.left) || Phaser.Input.Keyboard.JustDown(this.cursors.right)) {
      this.scene.start(SCENES.main);
    }
  }
}
