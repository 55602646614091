
import * as Phaser from 'phaser';
import { PLAYER_SPRITES, BAL, WEEKDAYS, SCENES, CONFIG, formatGameTime, REACTION_LISTS, FOOD_YUM_STOPS } from './consts';
import type { WEEKDAY, PlayerStats } from './consts';
import { StepBar, Label, ReactionOverlay, DayTitleCard } from './UI';
import { InputState } from './InputState';
import { Player } from './Player';
import { Food } from './Food';
import { Obstacle } from './Obstacle';


// ─── SCENE ────────────────────────────────────────────────────────────────────

export class MainScene extends Phaser.Scene {
  // Entities
  player: Player;
  foods: Phaser.Physics.Arcade.Group;
  obstacles: Phaser.Physics.Arcade.Group;
  alert: Phaser.GameObjects.Rectangle;

  // Input
  cursors: Phaser.Types.Input.Keyboard.CursorKeys;

  // Properties
  inputState: InputState;
  didTouchObstacle: boolean;
  previousPosition: { x: number, y: number };

  // Stats
  currentStep: number;
  currentDay: WEEKDAY;
  foodsCountYummy: number;
  foodsCountGross: number;
  yumGained: number;
  sprite: string;
  foodsPickedUp: { [key: string]: number };

  // UI
  StatCurrentDay: Label;
  StatCurrentStep: Label;
  StatCurrentTime: Label;
  stepBar: StepBar;

  constructor() {
    super({ key: SCENES.main });
  }

  preload() {
    Object.values(PLAYER_SPRITES).forEach((sprite) => {
      this.load.image(sprite, `assets/${sprite}.png`);
    });
    this.load.image('food-good', 'assets/food-good.png');
    this.load.image('food-med', 'assets/food-med.png');
    this.load.image('food-bad', 'assets/food-bad.png');
    this.load.image('food-trash', 'assets/food-trash.png');
  }

  create() {
    this.currentStep = 0;
    this.inputState = new InputState();
    this.cursors = this.input.keyboard.createCursorKeys();
    this.foodsCountYummy = 0;
    this.foodsCountGross = 0;
    this.didTouchObstacle = false;
    this.yumGained = 0;
    this.foodsPickedUp = {
      [FOOD_YUM_STOPS.good]: 0,
      [FOOD_YUM_STOPS.med]: 0,
      [FOOD_YUM_STOPS.bad]: 0,
      [FOOD_YUM_STOPS.trash]: 0,
    };
    this.beginFirstDay();
    this.StatCurrentTime = new Label(this, CONFIG.width * 0.5, CONFIG.height * 0.05, ``, 'sm', "center")
    this.StatCurrentTime.setAlpha(0)
    this.StatCurrentTime.setWeight("bold");
    this.input.keyboard.on('keydown', (e: KeyboardEvent) => this.handleKey(e));
  }

  // ─── Lifecycle Functions ─────────────────────────────────────────────

  update() {
    this.StatCurrentTime.setText(`${formatGameTime(this.scene.scene.time.now)}`)
    if (!this.inputState.isInputEnabled) return;

    if (this.isEndOfDay()) {
      this.endDay();
      return;
    };

    if (Phaser.Input.Keyboard.JustDown(this.cursors.up) || Phaser.Input.Keyboard.JustDown(this.cursors.down) ||
      Phaser.Input.Keyboard.JustDown(this.cursors.left) || Phaser.Input.Keyboard.JustDown(this.cursors.right)) {

      if (this.didTouchObstacle) {
        this.didTouchObstacle = false;
      } else {
        this.advanceStep();
      }
    }
  }

  destroy() {
    // Elements   
    this.player.destroy();
    this.foods.destroy(true);
    this.obstacles.destroy(true);

    // Ui
    this.stepBar.destroy();
    this.StatCurrentDay.destroy();
    this.StatCurrentStep.destroy();
  }

  // ─── Main Game Functions ─────────────────────────────────────────────

  private isEndOfDay() {
    if (this.currentStep >= BAL.stepsPerDay) return true;
    if (this.foods.children.size === 0) return true;

    return false;
  }

  private updateStats() {

    // Update scene UI
    this.StatCurrentDay.setText(`${this.currentDay}`);
    this.StatCurrentStep.setText(`- ${BAL.stepsPerDay - this.currentStep} ${BAL.stepsPerDay - this.currentStep === 1 ? 'Step' : 'Steps'} Remain -`);
    this.stepBar.update(this.currentStep);
    this.StatCurrentTime.setText(`${formatGameTime(this.scene.scene.time.now)}`);

    // console.clear()
    // console.log({
    //   yum: this.player.yum,
    //   foodsCountYummy: this.foodsCountYummy,
    //   foodsCountGross: this.foodsCountGross,
    //   currentStep: this.currentStep,
    //   currentDay: this.currentDay,
    //   yumGained: this.yumGained,
    //   elapsedMs: this.scene.scene.time.now
    // });
  }

  // ——─── Advance Step Function ─────────────────────────────────────────────

  advanceStep() {

    if (this.didTouchObstacle) return;

    // ─── Update Entities ─────────────────────────────────────────

    this.foods.children.each((food: Food) => {
      const randomFactor = Phaser.Math.FloatBetween(0.5, 1.5);
      food.decreaseYum(BAL.decFoodYumPerStep * randomFactor);
      food.update();
      return true;
    });

    // ─── Increment day step ────────────────────────────────

    this.currentStep++;

    // ─── Update UI ────────────────────────────────────────────

    this.updateStats();

  }

  private endDay() {

    this.inputState.disableInput();

    this.time.delayedCall(150, () => {

      if (this.currentDay === WEEKDAYS[4]) {

        let numTrashFoods = 0;

        this.foods.children.each((food: Food) => {
          if (food.yum <= BAL.foodYumThresholds.trash) {
            numTrashFoods++;
          }
          return true;
        })

        this.scene.start(SCENES.outro, {
          yum: this.player.yum,
          gross: this.foodsCountGross,
          yumGained: this.yumGained,
          elapsedMs: this.scene.scene.time.now,
          foodsPickedUp: this.foodsPickedUp,
          numTrashFoods,
          foods: this.foods,
          sprite: this.sprite
        }
        );
      } else {
        this.beginDay(WEEKDAYS[WEEKDAYS.indexOf(this.currentDay) + 1]);
      }

      // ─── Update Entities ─────────────────────────────────────────

      this.player.update();

      this.foods.children.each((food: Food) => {
        food.decreaseYum(BAL.decFoodYumPerStep);
        food.update();
        return true;
      });

      this.obstacles.children.each((obstacle: Phaser.GameObjects.Sprite) => {
        obstacle.destroy();
        return true;
      });

    }, [], this);
  }

  private beginFirstDay() {

    this.beginDay("Monday")

    this.time.delayedCall(1000, () => {
      this.player = new Player(this,
        this.sys.game.config.width as number / 2,
        this.sys.game.config.height as number / 2,
        this.inputState);

      this.sprite = this.player.texture.key;
      this.foods = this.physics.add.group({ classType: Food });
      this.obstacles = this.physics.add.group({ classType: Obstacle });
      this.physics.add.overlap(this.player, this.foods, this.touchFood, null, this);
      this.physics.add.overlap(this.player, this.obstacles, this.touchObstacle, null, this);

      // ─── Create Scene Ui and update stats ─────────────────────────────────────────

      this.StatCurrentDay = new Label(this, CONFIG.width / 2, CONFIG.height * 0.83, ``, 'md', "center");
      this.stepBar = new StepBar({ scene: this, totalSteps: BAL.stepsPerDay, x: CONFIG.width / 2, y: CONFIG.height * 0.88, width: 6, height: 0.25, countDirection: "down" });
      this.StatCurrentStep = new Label(this, CONFIG.width / 2, CONFIG.height * 0.94, ``, 'sm', "center");
      this.StatCurrentDay.setWeight("bold");

      this.tweens.add({
        targets: [this.StatCurrentTime.getTextObject()],
        alpha: 1,
        delay: 4000,
        duration: 4000,
        ease: 'Sine.easeInOut',
      });

      this.updateStats();
    }, [], this);
  }

  // ─── beginDay ─────────────────────────────────────────────────────

  private beginDay(day: WEEKDAY) {
    this.inputState.disableInput();
    const numDaysRemaining = WEEKDAYS.length - WEEKDAYS.indexOf(day) - 1;

    // ─── Show Day Start Title ───────────────────────────────────

    let subheading;
    if (numDaysRemaining === 0) {
      subheading = `- ${BAL.stepsPerDay} Steps Remain -`;
    } else if (numDaysRemaining === 1) {
      subheading = '- 1 Day Remains -';
    } else {
      subheading = `- ${numDaysRemaining + 1} Days Remain -`;
    }

    subheading = `- ${BAL.stepsPerDay * (numDaysRemaining + 1)} Steps Remain -`;

    new DayTitleCard({
      scene: this,
      currentDay: day,
      subheading,
      onFadeOutStart: () => {

        // ─── Reset Day ───────────────────────────────────────────────

        this.currentDay = day;
        this.currentStep = 0;
        this.player.increaseYum(BAL.playerStartYum - this.player.yum);
        this.foods.children.each((food: Food) => {
          food.decreaseYum(Infinity);
          food.update();
          return true;
        });

        // ─── Place New Food ─────────────────────────────────────────

        this.placeNewFood(BAL.foodsPerDay);

        // ─── Place New Obstacles ─────────────────────────────────────────


        // Enable input a little before the fade out is complete
        this.time.delayedCall(250, () => {
          this.inputState.enableInput();
        }, [], this);

        // ─── Update UI ─────────────────────────────────────────────

        this.updateStats();
      }
    });
  }

  // ─── placeNewFood Function ───────────────────────────────────────────

  placeNewFood(count: number) {
    const safeAreaSize = BAL.foodSpawnSafeAreaSize;
    const foodMinDistance = CONFIG.tileSize; // Minimum distance between any two food items

    for (let i = 0; i < count; i++) {
      let x, y;

      const maxX = Math.floor((this.sys.game.config.width as number) / CONFIG.tileSize);
      const maxY = Math.floor((this.sys.game.config.height as number) / CONFIG.tileSize);

      let isOverlap = false; // Flag for checking overlap

      do {
        x = Phaser.Math.Between(safeAreaSize, maxX - safeAreaSize - 1) * CONFIG.tileSize;
        y = Phaser.Math.Between(safeAreaSize, maxY - safeAreaSize - 1) * CONFIG.tileSize;

        isOverlap = this.foods.getChildren().some((food: Food) => Phaser.Math.Distance.Between(food.x, food.y, x, y) < foodMinDistance);
      } while (isOverlap || Phaser.Math.Distance.Between(this.player.x, this.player.y, x, y) < 100);

      let food = new Food(this, x, y, BAL.foodYumRange[1], () => {
        this.foodsCountGross++;
        this.updateStats();
      });
      this.foods.add(food);
    }
  }

  handleKey(e: KeyboardEvent) {
    this.didTouchObstacle = false;
    if (this.inputState.isInputEnabled === false) return;

    // Save the current coordinates
    this.previousPosition = { x: this.player.x, y: this.player.y };

    // Determine the next position from a key press
    switch (e.key) {
      case 'ArrowUp':
        this.player.y -= CONFIG.tileSize;
        break;
      case 'ArrowDown':
        this.player.y += CONFIG.tileSize;
        break;
      case 'ArrowLeft':
        this.player.x -= CONFIG.tileSize;
        break;
      case 'ArrowRight':
        this.player.x += CONFIG.tileSize;
        break;
    }
  }

  // ─── touchFood Function ────────────────────────────────────────────────

  // Function to handle the event when player collides with a food
  touchFood(player: Player, food: Food) {

    // Add yum to player
    this.player.increaseYum(food.yum);
    this.yumGained += food.yum;

    const foodYumStop = Food.interpretYum(food.yum);
    const reactionsForYumStop = REACTION_LISTS[foodYumStop];
    const reactionText = reactionsForYumStop[Phaser.Math.Between(0, reactionsForYumStop.length - 1)];
    this.foodsPickedUp[foodYumStop]++;

    // Destroy the food
    food.destroy();

    // Show the reaction text
    new ReactionOverlay(this, reactionText, this.player.x, this.player.y - this.player.height);
  }

  touchObstacle(player: Player, obstacle: Obstacle) {
    // Upon colliding, move the player back to the previous position
    this.player.x = this.previousPosition.x;
    this.player.y = this.previousPosition.y;

    this.didTouchObstacle = true;
  }

}
