import * as Phaser from 'phaser';
import { CONFIG, FONTS, FONT_SIZES, GAME_COLORS, GAME_EVENTS } from "./consts";
import { Player } from "./Player";
import { Food } from "./Food";

// ─── Label ──────────────────────────────────────────────────────────────────

export class Label {
  private label: Phaser.GameObjects.Text;
  private scene: Phaser.Scene;

  constructor(
    scene: Phaser.Scene,
    private x: number,
    private y: number,
    private text: string,
    private size: keyof typeof FONT_SIZES = 'md',
    private align: 'left' | 'center' | 'right' = 'center'
  ) {
    this.scene = scene;
    this.draw();
  }

  private draw() {
    let fontSize = FONT_SIZES[this.size];

    this.label = this.scene.add.text(this.x, this.y, this.text, {
      fontFamily: FONTS.normal,
      fontSize: `${fontSize}px`,
      color: GAME_COLORS.overlayTextHex,

    }).setOrigin(
      this.align === 'left' ? 0 : this.align === 'right' ? 1 : 0.5,
      0.5
    );
  }

  public setWeight(weight: 'normal' | 'bold'): void {
    if (weight === 'normal') {
      this.label.setFontFamily(FONTS.normal);
    }
    else if (weight === 'bold') {
      this.label.setFontFamily(FONTS.bold);
    }
  }

  public setAlpha(value) {
    this.label.setAlpha(value)
  };

  public setPosition(x: number, y: number) {
    this.label.setPosition(x, y)
  };

  public setDepth(value: number): void {
    this.label.setDepth(value);
  }

  public getTextObject(): Phaser.GameObjects.Text {
    return this.label;
  }

  public destroy() {
    this.label.destroy();
  }

  public setText(newText: string) {
    this.text = newText;
    this.label.setText(this.text);
  }
}

// ─── Alert ───────────────────────────────────────────────────────────────────

export const Alert = (
  scene: Phaser.Scene,
  heading: string,
  detail?: string
) => {
  const positionX = scene.sys.game.config.width as number / 2
  const positionY = scene.sys.game.config.height as number / 2

  const width = 150;
  const height = 100;

  const titleCard = scene.add.rectangle(
    positionX,
    positionY,
    width,
    height,
    GAME_COLORS.background

  ).setOrigin(0.5);

  const titleText = new Label(scene, positionX, positionY, heading, 'lg');
  const descriptionText = new Label(scene, positionX, positionY + 20, detail, 'sm');

  // Fade out the title card and text
  scene.tweens.add({
    targets: [titleCard, titleText, descriptionText],
    alpha: 0,
    delay: 200,
    duration: 1500,
    onComplete: () => {
      titleCard.destroy();
      titleText.destroy();
      descriptionText.destroy();
    }
  });

  return titleCard;
}


// ─── Health Bar ──────────────────────────────────────────────────────────────

const HealthBarConfig = {
  size: [CONFIG.tileSize, CONFIG.tileSize / 4] as [number, number],
  roundness: CONFIG.tileSize / 4 / 2,
  offsetX: 0,
  offsetY: 0.625 * CONFIG.tileSize,
}

export class HealthBar {

  // ─── Properties ──────────────────────────────────────────────

  scene: Phaser.Scene;
  subject: Player | Food;  // reference to the player
  value: number;
  maxValue: number;
  bar: Phaser.GameObjects.Graphics;
  mask: Phaser.GameObjects.Graphics;
  outline: Phaser.GameObjects.Graphics;

  // ─── Constructor ─────────────────────────────────────────────────────────────

  constructor(scene: Phaser.Scene, subject: Player | Food) {
    this.scene = scene;
    this.subject = subject;
    this.maxValue = subject.maxYum;
    this.value = subject.yum;
    this.outline = this.scene.add.graphics();
    this.mask = this.scene.make.graphics({}, false);
    this.bar = this.scene.add.graphics();
    this.draw();
  }

  // ─── Draw function ───────────────────────────────────────────────────────────────────

  draw() {
    return;
    let { x, y } = this.subject;

    const pos = [x + HealthBarConfig.offsetX - (HealthBarConfig.size[0] / 2), y + HealthBarConfig.offsetY] as [number, number];
    const roundness = Math.min(HealthBarConfig.roundness, HealthBarConfig.size[0] / 2, HealthBarConfig.size[1] / 2);

    this.mask.clear();
    this.mask.fillStyle(0xffffff, 1);  // As we only need the shape, the color won't matter
    this.mask.fillRoundedRect(...pos, ...HealthBarConfig.size, roundness);

    this.outline.clear();
    this.outline.lineStyle(1, 0xffffff, 1);
    this.outline.strokeRoundedRect(...pos, ...HealthBarConfig.size, roundness);

    this.bar.clear();
    this.bar.fillStyle(0xffffff, 1);
    this.bar.fillRect(...pos, (this.value / this.maxValue) * HealthBarConfig.size[0], HealthBarConfig.size[1]);
    this.bar.setMask(this.mask.createGeometryMask());

  }

  // ─── Lifecycle ───────────────────────────────────────

  update(value: number) {
    this.value = value;
    this.draw();
  }

  destroy() {
    this.bar.destroy();
    this.outline.destroy();
  }
}

// ─── StepBar ─────────────────────────────────────────────────────────

type StepBarOptions = {
  scene: Phaser.Scene;
  totalSteps: number;
  x: number;
  y: number;
  width: number;
  height: number;
  countDirection?: 'up' | 'down';
};


export class StepBar {

  // Properties

  scene: Phaser.Scene;
  totalSteps: number;
  stepCount: number;
  bar: Phaser.GameObjects.Graphics;
  outline: Phaser.GameObjects.Graphics;
  mask: Phaser.GameObjects.Graphics;
  container: Phaser.GameObjects.Container;
  x: number;
  y: number;
  width: number;
  height: number;
  countDirection: 'up' | 'down' = 'up';
  isAnimPlaying: boolean = false;

  // Constructor

  constructor(options: StepBarOptions) {

    // data
    this.scene = options.scene;
    this.totalSteps = options.totalSteps;
    this.stepCount = 0;
    this.countDirection = options.countDirection || 'up';

    // ui
    this.x = options.x;
    this.y = options.y;
    this.container = this.scene.add.container(this.x, this.y);
    this.mask = options.scene.make.graphics({}, false);
    this.outline = options.scene.add.graphics();
    this.bar = options.scene.add.graphics();
    this.container.add([this.outline, this.bar, this.mask])
    this.width = options.width * CONFIG.tileSize;
    this.height = options.height * CONFIG.tileSize;
    this.draw();
  }

  pulse() {
    const scale = 1.5;

    if (!this.isAnimPlaying) {
      this.isAnimPlaying = true;
      this.scene.tweens.add({
        targets: this.container,
        alpha: 0.8,
        scaleY: scale,
        y: this.y - this.height * (scale - 1) / 2,
        yoyo: true,
        duration: 150,
        ease: 'Power1',
        repeat: 0,
        onComplete: () => {
          this.isAnimPlaying = false;
        }
      });
    }
  }

  // Draw function

  draw() {
    const size = [this.width, this.height] as [number, number];
    const pos = [0, 0] as [number, number];
    const roundness = Math.min(size[0], size[1] / 2, size[1] / 2);

    const outline = this.container.getAt(0) as Phaser.GameObjects.Graphics
    const bar = this.container.getAt(1) as Phaser.GameObjects.Graphics

    this.container.x = this.x - this.width / 2;

    outline
      .clear()
      .lineStyle(2, GAME_COLORS.text, 1)
      .strokeRoundedRect(...pos, ...size, roundness)

    bar
      .clear()
      .fillStyle(GAME_COLORS.text, 1);

    if (this.countDirection === 'up') {
      const fillWidth = (this.stepCount / this.totalSteps) * this.width;
      bar.fillRoundedRect(pos[0], pos[1], fillWidth, this.height, 4);
    } else {
      const fillWidth = (1 - (this.stepCount / this.totalSteps)) * this.width
      const fillX = this.width / 2 - fillWidth / 2;
      bar.fillRoundedRect(fillX, pos[1], fillWidth, this.height, 4);
    }

  }
  // Lifecycle

  update(stepCount: number) {
    this.stepCount = stepCount;
    this.draw();
    this.pulse();
  }

  destroy() {
    this.container.destroy();
  }
}

// ─── Day Title Card ─────────────────────────────────────────────────────────


type DayTitleCardOptions = {
  scene: Phaser.Scene;
  currentDay: string;
  subheading?: string;
  onFadeOutStart?: () => void;
  onCompleted?: () => void;
};

export class DayTitleCard {
  scene: Phaser.Scene;
  container: Phaser.GameObjects.Container;  // Container to hold all elements
  titleBackground: Phaser.GameObjects.Graphics;
  dawnOfTextRenderer: Label;
  headingTextRenderer: Label;
  subheadingTextRenderer?: Label;
  onFadeOutStart?: () => void;
  onCompleted?: () => void;

  constructor(options: DayTitleCardOptions) {
    this.scene = options.scene;

    // Black background
    this.titleBackground = this.scene.add.graphics();
    this.titleBackground.fillStyle(0x000000, 1);
    this.titleBackground.fillRect(0, 0, CONFIG.width, CONFIG.height);

    this.container = this.scene.add.container(0, 0,
      [this.titleBackground,]);

    // "Dawn of" heading
    this.dawnOfTextRenderer = new Label(this.scene,
      this.scene.sys.game.config.width as number / 2,
      this.scene.sys.game.config.height as number * 0.41,
      "Dawn of",
      'lg'
    );
    this.dawnOfTextRenderer.setWeight('bold');

    this.container.add(this.dawnOfTextRenderer.getTextObject());

    // Day heading
    this.headingTextRenderer = new Label(this.scene,
      this.scene.sys.game.config.width as number / 2,
      this.scene.sys.game.config.height as number * 0.51,
      options.currentDay,
      'xl'
    );
    this.headingTextRenderer.setWeight('bold');
    this.container.add(this.headingTextRenderer.getTextObject());

    // Subheading
    if (options.subheading) {
      this.subheadingTextRenderer = new Label(this.scene,
        this.scene.sys.game.config.width as number / 2,
        this.scene.sys.game.config.height as number * 0.63,
        options.subheading,
        'md');

      this.container.add(this.subheadingTextRenderer.getTextObject());
    }

    this.container.setDepth(100);  // Should be set once after all elements are added
    this.container.setAlpha(0); // Start out transparent

    // dim the screen when the title card is shown
    this.scene.game.events.emit(GAME_EVENTS.dimScreen);

    // Fade in the title card and text
    this.scene.tweens.add({
      targets: this.container,
      alpha: 1,
      duration: 1000,
      ease: 'Power1',
      onComplete: () => {
        this.scene.tweens.add({
          targets: this.container,
          alpha: 0,
          delay: 1500,
          duration: 1500,
          ease: 'Power1',
          onStart: () => {
            this.scene.game.events.emit(GAME_EVENTS.undimScreen);
            if (options.onFadeOutStart) {
              options.onFadeOutStart();
            }
          },
          onComplete: () => {
            if (options.onCompleted) {
              options.onCompleted();
            }
            this.destroy();
          }
        });
      }
    });
  }

  destroy() {
    this.container.destroy(true);  // true to also destroy all children
  }
}

// ─── Reaction Overlay ─────────────────────────────────────────────────────────

export class ReactionOverlay {
  private scene: Phaser.Scene;
  private background: Phaser.GameObjects.Graphics;
  private textObject: Phaser.GameObjects.Text;
  private container: Phaser.GameObjects.Container;

  constructor(
    scene: Phaser.Scene,
    reaction: string,
    x: number,
    y: number
  ) {
    this.scene = scene;

    const randomX = x + (Math.random() - 0.5) * CONFIG.tileSize * 2;
    const randomY = y + (Math.random() - 0.5) * CONFIG.tileSize * 0.25;

    // ─── Text ────────────────────────────────────────────────────

    this.textObject = this.scene.add.text(0, 0, reaction, {
      color: GAME_COLORS.overlayTextHex,
      fontSize: FONT_SIZES.sm,
      fontFamily: FONTS.bold,
      align: 'center',
    })
      .setOrigin(0.5)
      .setDepth(100);

    // ─── Background ──────────────────────────────────────────────

    const backgroundSize = [
      this.textObject.width + CONFIG.tileSize * 0.5,
      this.textObject.height + CONFIG.tileSize * 0.25
    ] as [number, number];

    const roundness = Math.min(backgroundSize[0], backgroundSize[1] / 2, backgroundSize[1] / 2);
    const backgroundPos = [-backgroundSize[0] / 2, -backgroundSize[1] / 2] as [number, number];

    this.background = this.scene.add.graphics()
      .fillStyle(0x000000, 0.5)
      .fillRoundedRect(...backgroundPos, ...backgroundSize, roundness)
      .setDepth(0);

    // ─── Container ────────────────────────────────────────────────────

    this.container = this.scene.add.container(randomX, randomY, [this.background, this.textObject]);

    this.startTween();
  }

  private startTween() {
    const randomAngle = (Math.random() - 0.5) * 30;

    this.scene.tweens.add({
      targets: [this.container],
      y: "-=50",
      alpha: 0,
      angle: randomAngle,
      duration: 2000,
      onComplete: () => {
        this.container.destroy()
      }
    });
  }
}

// ─── Centered Layout ─────────────────────────────────────────────────────────

export class CenteredLayout extends Phaser.GameObjects.Container {
  public height: number;
  private layoutContainer: Phaser.GameObjects.Container;

  constructor(
    public scene: Phaser.Scene,
    public x: number,
    public y: number,
    public gameObjects: (Label | Phaser.GameObjects.Sprite)[],
    public config: { lineSpacing: number }
  ) {
    super(scene);
    this.layoutContainer = this.scene.add.container();
    this.createObjects();
  }

  private createObjects() {
    let totalHeight = 0;

    // Create a separate array to store the Phaser objects and their heights
    const gameObjectDimensions = [];

    this.gameObjects.forEach((gameObject, index) => {
      let phaserObject;
      let objectHeight;

      if (gameObject instanceof Label) {
        phaserObject = gameObject.getTextObject();
        objectHeight = phaserObject.getHeight();
      } else {
        // Treat as Phaser.GameObjects.Sprite
        phaserObject = gameObject;
        objectHeight = gameObject.displayHeight;
      }

      // Cumulative height of the objects and their spacing
      totalHeight += objectHeight + this.config.lineSpacing;

      // Store Phaser object and its height for later positioning
      gameObjectDimensions[index] = { phaserObject, height: objectHeight };
    });

    // Calculate the starting Y position of the first object
    let currentY = -totalHeight / 2;

    // Position the gameObjects
    gameObjectDimensions.forEach(({ phaserObject, height }) => {
      phaserObject.setPosition(0, currentY + height / 2).setOrigin(0.5, 0.5);
      this.layoutContainer.add(phaserObject);
      currentY += height + this.config.lineSpacing;
    });

    // Set the position of the entire layoutContainer
    this.layoutContainer.setPosition(this.x, this.y);
  }


  public destroy() {
    this.layoutContainer.destroy();
  }

  public setPositon(x: number, y: number) {
    this.layoutContainer.setPosition(x, y);
  }

}