import * as Phaser from 'phaser';

const tileSize = 64;

// ─── Canvas Config ────────────────────────────────────────────────────────────

export const CONFIG = {
  tileSize: tileSize as number,
  width: tileSize * 20 as number,
  height: tileSize * 12 as number,
}

export const PLAYER_SPRITES = {
  1: 'player-1',
  2: 'player-2',
  3: 'player-3',
  4: 'player-4',
} as const;

export const FOOD_YUM_STOPS = {
  bad: 'bad',
  med: 'med',
  good: 'good',
  trash: 'trash',
} as const;

// ─── Style ───────────────────────────────────────────────────────────────────

export const FONTS = {
  bold: 'Nunito Sans Extra',
  normal: 'Nunito Sans Semi',
} as const;

export const FONT_SIZES = {
  xl: tileSize * 0.5 * 3 as number,
  lg: tileSize * 0.5 * 1.5 as number,
  md: tileSize * 0.5 as number,
  sm: tileSize * 0.5 * 0.75 as number,
  xs: tileSize * 0.5 * 0.5 as number,
} as const;

const PALETTE = {
  brandOrange: 0xff771a,
  brandOrangeDark: 0xef4023,
  brandBlack: 0x3b3b3b,
  brandBlackDark: 0x222222,
  brandWhite: 0xf6f2ef,
  brandWhiteDark: 0xe0dad5,
  white: 0xffffff,
  black: 0x000000,
} as const;

const BASE_COLORS = {
  background: PALETTE.brandOrange,
  backgroundDark: PALETTE.brandOrangeDark,
  text: PALETTE.white,
  overlayBackground: PALETTE.brandBlackDark,
  overlayText: PALETTE.white,
}

type BaseColorKeys = keyof typeof BASE_COLORS;
type HexColorKeys = `${BaseColorKeys}Hex`

type GAME_COLORS = {
  [K in BaseColorKeys]: number;
} & {
    [K in HexColorKeys]?: string;
  } & {
    overlayAlpha: number;
  };

export const GAME_COLORS: GAME_COLORS = {
  ...BASE_COLORS,
  ...Object.keys(BASE_COLORS).reduce<any>((newColors, cKey) => ({
    ...newColors,
    [cKey + 'Hex']: `#${('000000' + (BASE_COLORS[cKey as BaseColorKeys] | 0x1000000).toString(16)).slice(-6)}`
  }), {}),
  overlayAlpha: 0.5,
};

// ─── Balance ─────────────────────────────────────────────────────────────────

const FOOD_YUM_RANGE = [0, 100] as [number, number];

export const BAL = {
  parGameTimeMs: 1000 * 30 as number,
  stepsPerDay: 28 as number,
  foodsPerDay: 8 as number,
  decPlayerYumPerStep: 4 as number,
  decFoodYumPerStep: FOOD_YUM_RANGE[1] / 27 as number,
  allowedLeftovers: 0 as number,
  obstaclesPerDay: 8 as number,
  foodYumRange: FOOD_YUM_RANGE,
  foodYumThresholds: {
    [FOOD_YUM_STOPS.trash]: FOOD_YUM_RANGE[0],
    [FOOD_YUM_STOPS.bad]: FOOD_YUM_RANGE[1] * 0.3,
    [FOOD_YUM_STOPS.med]: FOOD_YUM_RANGE[1] * 0.6,
    [FOOD_YUM_STOPS.good]: FOOD_YUM_RANGE[1] * 0.8,
  },
  foodSpawnSafeAreaSize: 4 as number,
  playerYumRange: [0, 100] as [number, number],
  playerStartYum: 75 as number,
} as const;

export type BAL = typeof BAL;

// ─── Consts ──────────────────────────────────────────────────────────────────

export const GAME_NAME = "A Trash Game";

export const WEEKDAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"] as const;

export type WEEKDAY = typeof WEEKDAYS[number];

export const SCENES = {
  boot: "Boot",
  intro: "Intro",
  main: "Main",
  outro: "Outro"
} as const;

export type SCENE = typeof SCENES[keyof typeof SCENES];

export type PlayerStats = {
  yum: number;
  gross: number;
  yumGained: number;
  numTrashFoods: number;
  elapsedMs: number;
  foodsPickedUp: { [key: string]: number };
  sprite: string;
};

export type FOOD_YUM_STOP = typeof FOOD_YUM_STOPS[keyof typeof FOOD_YUM_STOPS];

export const GAME_EVENTS = {
  dimScreen: 'dimScreen',
  undimScreen: 'undimScreen',
  wonGame: 'wonGame',
  lostGame: 'lostGame',
  gameOver: 'gameOver',
} as const;

export const formatGameTime = (ms: number) => {
  return (ms / 1000).toFixed(2)
}

// ─── Copy ───────────────────────────────────────────────────────────────────

export const REACTION_LISTS = {
  [FOOD_YUM_STOPS.good]: [
    "yum!",
    "gas",
    "LFG",
    "delicious",
    "mmm mmm",
    "tasty",
    "yum yum yum",
  ],
  [FOOD_YUM_STOPS.med]: [
    "okay",
    "tastes like food",
    "this is ok i guess",
    "mehhhhh",
    "meh",
    "wish we still had M&Ms",
  ],
  [FOOD_YUM_STOPS.bad]: [
    "ew!",
    "gross!",
    "yuck!",
    "ewwwwww",
    "moldy!",
    "gross gross gross!",
  ],
  [FOOD_YUM_STOPS.trash]: [
    "trashtown",
    "I should have eaten this earlier",
    "this was food once",
    "trash!",
    "goodbye",
  ],
} as const;

// TEST DATA

export const TEST_STAT = {
  yum: 0,
  gross: 2,
  yumGained: 0,
  foodsPickedUp: {
    [FOOD_YUM_STOPS.bad]: 5,
    [FOOD_YUM_STOPS.med]: 5,
    [FOOD_YUM_STOPS.good]: 5,
    [FOOD_YUM_STOPS.trash]: 5,
  },
  numTrashFoods: 0,
  elapsedMs: 100,
  sprite: PLAYER_SPRITES[1],
} as const;