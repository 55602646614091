import * as Phaser from 'phaser';
import { CONFIG, PlayerStats, SCENES, formatGameTime, BAL, PLAYER_SPRITES, GAME_EVENTS } from './consts';
import { Label } from './UI';




const screenCenterX = CONFIG.width / 2

function playTimeYpos() { return CONFIG.height * 0.05 }
const parTimeYPos = playTimeYpos() + CONFIG.height * 0.05;

function playerSpriteYpos(didWin) {
  if (didWin) {
    return CONFIG.height * 0.25;
  } else {
    return CONFIG.height * 0.3;
  }
}
function titleYpos(didWin) {
  if (didWin) {
    return CONFIG.height * 0.4;
  } else {
    return CONFIG.height * 0.45;
  }
}
function loseMessageYPos() { return CONFIG.height * 0.55; }

function pickupsYpos() { return CONFIG.height * 0.5; }
function playAgainImageYpos(didWin) {
  if (didWin) {
    return CONFIG.height * 0.825;
  } else {
    return CONFIG.height * 0.8;
  }
}
function playAgainCaptionYpos(didWin) {
  return playAgainImageYpos(didWin) + CONFIG.height * 0.1;
}


export class OutroScene extends Phaser.Scene {
  cursors: Phaser.Types.Input.Keyboard.CursorKeys;
  canGoAgain: boolean;

  constructor() {
    super({ key: SCENES.outro });
  }

  preload() {
    Object.values(PLAYER_SPRITES).forEach((sprite) => {
      this.load.image(sprite, `assets/${sprite}.png`);
    });
    this.load.image('keys', 'assets/keys.png');
    this.load.image('food-good', 'assets/food-good.png');
    this.load.image('food-med', 'assets/food-med.png');
    this.load.image('food-bad', 'assets/food-bad.png');
    this.load.image('food-trash', 'assets/food-trash.png');
  }

  create(playerStats: PlayerStats) {

    const centerX = this.sys.game.config.width as number / 2;
    const container = this.add.container(0, 0);

    const foodsPickedUp = {
      'food-good': playerStats.foodsPickedUp?.good || 0,
      'food-med': playerStats.foodsPickedUp?.med || 0,
      'food-bad': playerStats.foodsPickedUp?.bad || 0,
      'food-trash': playerStats.foodsPickedUp?.trash || 0,
    };

    this.canGoAgain = false;

    const didWin = playerStats.numTrashFoods === 0;

    // ——— Game Events ————————————————————————————————————————————————————————

    this.game.events.emit(GAME_EVENTS.gameOver);
    if (didWin) {
      this.game.events.emit(GAME_EVENTS.wonGame);
    } else {
      this.game.events.emit(GAME_EVENTS.lostGame);
    }

    // ─── Player ────────────────────────────────────────────────────

    const playerSprite = this.add.sprite(centerX, playerSpriteYpos(didWin), playerStats.sprite);
    playerSprite.setDisplaySize(CONFIG.tileSize * 2, CONFIG.tileSize * 2);
    playerSprite.setOrigin(0.5, 0.5);

    if (!didWin) {
      playerSprite.setTexture('food-trash');
    }

    this.add.tween({
      targets: [playerSprite],
      angle: "-=10",
      duration: 2000,
      ease: 'Sine.easeInOut',
      yoyo: true,
      repeat: -1
    });

    this.add.tween({
      targets: [playerSprite],
      scaleX: 1.1,
      scaleY: 1.1,
      duration: 1500,
      ease: 'Sine.easeInOut',
      yoyo: true,
      repeat: -1
    });

    // ─── Title ────────────────────────────────────────────────────

    const titleText = didWin ? "You Won!" : "Game over";

    const title = new Label(this, centerX, titleYpos(didWin), titleText, 'xl');
    title.setWeight('bold');
    container.add([title.getTextObject()]);

    // ——— FOOD STATS ————————————————————————————————————————————————————————

    if (didWin) {

      const distanceX = CONFIG.tileSize * 0.5;
      const distanceY = CONFIG.tileSize * 0.75;

      const foodIconsContainer = this.add.container(0, 0);

      let currentY = pickupsYpos();

      Object.entries(foodsPickedUp).forEach(([key, count]) => {
        for (let i = 0; i < count; i++) {
          const iconX = centerX - distanceX * Math.floor(count / 2) + (i * distanceX);
          const foodIcon = this.add.image(iconX, currentY, key);
          foodIcon.width = CONFIG.tileSize / 2;
          foodIcon.height = CONFIG.tileSize / 2;
          foodIcon.setDisplaySize(CONFIG.tileSize / 2, CONFIG.tileSize / 2);

          const randomDurationX = Phaser.Math.Between(500, 2000);
          const randomDurationY = Phaser.Math.Between(500, 2000);
          const randomDelayX = Phaser.Math.Between(0, 500);
          const randomDelayY = Phaser.Math.Between(0, 500);
          const randomWiggleAmountX = 0.1 * Phaser.Math.FloatBetween(0.8, 1.2);
          const randomWiggleAmountY = 0.1 * Phaser.Math.FloatBetween(0.8, 1.2);
          const randomDirectionX = Phaser.Math.FloatBetween(0, 1) > 0.5 ? '+' : '-';
          const randomDirectionY = Phaser.Math.FloatBetween(0, 1) > 0.5 ? '+' : '-';

          this.add.tween({
            targets: [foodIcon],
            x: {
              value: `${randomDirectionX}=${distanceX * randomWiggleAmountX}`,
              yoyo: true,
              repeat: -1,
              ease: 'Sine.easeInOut',
              duration: randomDurationX,
              delay: randomDelayX,
            },
            y: {
              value: `${randomDirectionY}=${distanceY * randomWiggleAmountY}`,
              yoyo: true,
              repeat: -1,
              ease: 'Sine.easeInOut',
              duration: randomDurationY,
              delay: randomDelayY,
            },
          });
          foodIconsContainer.add(foodIcon);
        }
        currentY += distanceY;
      });
    }

    // ─── Lose Message ────────────────────────────────────────────────────

    if (!didWin) {
      const loseMessage = new Label(this, centerX, loseMessageYPos(), "End Friday with no trash to win", 'md');
      loseMessage.setWeight('bold');
      container.add([loseMessage.getTextObject()]);
    }

    // ─── Play Time ────────────────────────────────────────────────────

    const playTime = new Label(this, centerX, playTimeYpos(), formatGameTime(playerStats.elapsedMs) + " seconds", 'sm');
    playTime.setWeight('normal');
    container.add([playTime.getTextObject()]);

    // ─── Par Time ────────────────────────────────────────────────────

    const parTime = new Label(this, centerX, parTimeYPos, "Par: " + formatGameTime(BAL.parGameTimeMs), 'sm');
    parTime.setWeight('normal');
    container.add([parTime.getTextObject()]);


    // ─── Play Again? ────────────────────────────────────────────────────

    const arrows = this.add.image(screenCenterX, playAgainImageYpos(didWin), 'keys')
      .setOrigin(0.5, 0.5)
      .setDepth(1)
      .setAlpha(0);

    const caption = new Label(this, screenCenterX, playAgainCaptionYpos(didWin), "P L A Y   A G A I N ?", 'sm');
    caption.setWeight('bold');
    caption.setAlpha(0);

    this.time.delayedCall(3000, () => {

      this.add.tween({
        targets: [caption.getTextObject(), arrows],
        alpha: 1,
        duration: 1000,
        ease: 'Sine.easeInOut',
        onComplete: () => {
          this.canGoAgain = true;

          this.tweens.add({
            targets: [caption.getTextObject(), arrows],
            alpha: 0.4,
            yoyo: true,
            duration: 400,
            ease: 'Sine.easeInOut',
            loop: -1
          });
        }
      });
    })



    this.cursors = this.input.keyboard.createCursorKeys();

    // Fade in the scene

    this.cameras.main.fadeIn(2000, 0, 0, 0);
  }

  // ─── Handle Updates ──────────────────────────────────────────────────

  update() {
    if (!this.canGoAgain) return;

    if (Phaser.Input.Keyboard.JustDown(this.cursors.up) ||
      Phaser.Input.Keyboard.JustDown(this.cursors.down) ||
      Phaser.Input.Keyboard.JustDown(this.cursors.left) ||
      Phaser.Input.Keyboard.JustDown(this.cursors.right)) {
      this.scene.start(SCENES.main);
    }
  }
}
