import * as Phaser from 'phaser';
import { CONFIG, BAL, PLAYER_SPRITES } from './consts';
import { InputState } from './InputState';
import { HealthBar } from './UI';

// ─── Consts ──────────────────────────────────────────────────────────────────

const sizeX = CONFIG.tileSize
const sizeY = CONFIG.tileSize

// ─── Main Class ──────────────────────────────────────────────────────────────
export class Player extends Phaser.Physics.Arcade.Sprite {

  // ─── Properties ──────────────────────────────────────────────────────

  yum: number;
  maxYum: number;
  healthBar: HealthBar;
  inputState: InputState;

  // ─── Constructor ─────────────────────────────────────────────────────

  constructor(scene: Phaser.Scene, x: number, y: number, inputState: InputState) {

    // ─── Core Init ───────────────────────────────────────────────

    super(scene, x, y, 'player');
    scene.physics.world.enable(this); // enable physics
    scene.add.existing(this); // add to scene

    // ─── Properties ─────────────────────────────────────────

    this.inputState = inputState;
    this.yum = BAL.playerStartYum;
    this.maxYum = BAL.playerYumRange[1];

    // ─── Addons ──────────────────────────────────────────────────

    this.healthBar = new HealthBar(scene, this);
    this.healthBar.update(this.yum);

    // ─── Init Input ──────────────────────────────────────────────

    // scene.input.keyboard.on('keydown', (e: KeyboardEvent) => this.handleKey(e));

    // ─── Sprite Init ─────────────────────────────────────────────

    const selectedSprite = PLAYER_SPRITES[Phaser.Utils.Array.GetRandom([...Object.keys(PLAYER_SPRITES)])];
    this.setTexture(selectedSprite);
    this.setSize(sizeX / 2, sizeY / 2);
    this.setDisplaySize(sizeX, sizeY);
  }

  // ─── Most Used Functions ──────────────────────────────────────────────

  decreaseYum(amount: number) {
    this.yum = Phaser.Math.Clamp(this.yum - amount, ...BAL.playerYumRange);
    this.healthBar.update(this.yum)
  }

  increaseYum(amount: number) {
    this.yum = Phaser.Math.Clamp(this.yum + amount, ...BAL.playerYumRange);
    this.healthBar.update(this.yum)
  }

  // ─── Event Handlers ──────────────────────────────────────────────────

  moveTo(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
  moveLeft() {
    this.x -= sizeX;
  }
  moveRight() {
    this.x += sizeX;
  }
  moveUp() {
    this.y -= sizeY;
  }
  moveDown() {
    this.y += sizeY;
  }

  // ─── Phaser Lifecycle Hooks ──────────────────────────────────────────

  destroy(fromScene?: boolean) {
    this.healthBar.destroy();
    super.destroy(fromScene);
  }

  update() {
    this.healthBar.update(this.yum);
  }
}
